section.section.section-punch {
  @include sectionFlex();
  flex-direction: column;
  text-align: left;
  background-image: url("/assets/img/bkg_lechasseur_1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 4em 0 10em 0;
  @media only screen and (min-width: 480px) {
    @include sectionFlex();
    margin-top: -100px;
    padding: 4em 0 10em 0 !important;
  }

  h1 {
    color: white;
  }

  h2 {

    span {
      color: $primaryColor;
    }
  }

  h1, h2 {
    color: white !important;

    @media only screen and (min-width: 768px) {
      margin-top: 0;
    }
    @media only screen and (min-width: 1024px) {

    }
  }

  h4 {

  }

  h1, h2, h3, h4 {
    text-align: center;

    @media only screen and (min-width: 700px) {
    }
  }

  p {
    color: $primaryColorReverse;
  }

  .button-primary {
    margin: 12px 0;
  }

  .main_logo {

    img {
      margin-top: 30px;
      width: 100%;
      height: auto;
    }

    @media only screen and (min-width: $mobileBreakpoint) {
      img {
        margin-top: inherit;
        width: inherit;
      }
    }
  }

  //dotted punch
  .maintag {
    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: column;
    @media only screen and (min-width: 1024px) {
      margin: 20px;
    }
    @media only screen and (min-width: 700px) {
    }
  }

  .flamingBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding-top: 100%;
    background-image: linear-gradient(180deg, #000000 0%, #101010 100%);
    position: relative;
    border-radius: 0 60px 0 28px;
    border: 3px solid $primaryColor;
    transform: scale(1, 1);
    transition: all 0.5s ease-in-out;
    transition-timing-function: cubic-bezier(3, 1, 2, 7);
    box-shadow: 0 0 4px -12px rgba(0, 0, 0, 0.9);
    margin: 10px 0;

    &:after {
      content: "";
      position: absolute;
      bottom: -1px;
      right: -1px;
      background-image: url("/assets/img/bottom_right_flame.svg");
      background-repeat: no-repeat;
      background-position: bottom right;
      background-size: 100% auto;
      width: 60%;
      height: 100%;
      overflow: hidden;
      z-index: 0;
    }

    &:hover {
      transform: scale(1.05, 1.05);
      transition: all 0.5s ease-in-out;
      transition-timing-function: cubic-bezier(3, 1, 2, 7);
      box-shadow: 0 18px 17px -18px rgba(0, 0, 0, 0.5);
    }

    .festinImg {
      width: 70%;
      height: auto;
    }

    span {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      align-content: center;
      position: absolute;
      color: white;
      z-index: 1;
      width: 100%;
      height: 100%;
      top: 0;
      text-align: center;
      padding-top: 20%;

      .title, .subtitle {
        margin: 0;
      }

      .title {
        font-size: 21px;
        font-weight: 800;
      }

      .subtitle {
        font-size: 11px;
        font-weight: 500;
        text-transform: uppercase;
      }

      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}
