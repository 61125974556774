section.section.section-features {
  @include sectionFlex();
  min-height: 40vh;
  text-align: center;
  padding-bottom: 70px;

  #menu_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    margin: .5rem 2%;

    .menuBox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;
      padding-top: 100%;
      position: relative;
      border-radius: 0 60px 0 28px;
      background-image: linear-gradient(157deg, #860300 0%, #BA2725 100%);
      flex-basis: 100%;
      flex-grow: 0;
      flex-shrink: 0;
      margin: 10px;
      width: 100%;
      transform: scale(1,1);
      transition: all 0.5s ease-in-out;
      transition-timing-function: cubic-bezier(3, 1, 2, 7);
      box-shadow: 0 0 4px -12px rgba(0,0,0,0.9);

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        background-image: url("/assets/img/bottom_right_flame_wh.svg");
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: 100% auto;
        width: 60%;
        height: 100%;
        overflow: hidden;
        z-index: 0;
      }

      &:hover {
        transform: scale(1.05,1.05);
        transition: all 0.5s ease-in-out;
        transition-timing-function: cubic-bezier(3, 1, 2, 7);
        box-shadow: 0 18px 17px -18px rgba(0,0,0,0.5);
      }

      span {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: baseline;
        align-content: center;
        position: absolute;
        color: white;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        padding: 14px;

        img {
          width: 40px;
        }

        .supTitle, .title {
          margin: 0;
          line-height: 1.3em;
          text-align: left;
        }
        .supTitle {
          font-size: 1em;
          text-transform: uppercase;
          font-weight: 500;
          margin-top: 8px;
        }
        .title {
          font-size: 21px;
          font-weight: 800;
        }
      }
    }

    @media only screen and (min-width: $mobileBreakpoint) {
      flex-direction: row;

      .menuBox {
        padding-top: 30%;
        flex-basis: 30%;
        margin: 10px;

        span {
          img {
            width: 30px;
          }
          .supTitle {
            font-size: 0.75em;
          }

          .title {
            font-size: 17px;
          }
        }
      }
    }
    @media only screen and (min-width: 1200px) {
      flex-direction: row;

      .menuBox {
        span {
          img {
            width: 30px;
          }
          .supTitle {
            font-size: 0.9em;
          }

          .title {
            font-size: 21px;
          }
        }
      }
    }
  }

  &#menus {
    padding: 5em 0;
    h3 {
      font-size: 30px;
    }
    h5, h5.number {
      font-size: 16px;
    }
    h3, h4, h5, {
      a {
        color:white;
        text-decoration: none;
      }
    }
  }

  @media only screen and (min-width: 1140px) {
    .productpict img {
      width: 100%;
    }
  }
}

i.fa {
  font-size: 3em;
}

.icon {
  transition: all .5s .3s ease-in-out;
  width: 75px;

  &.horiz {
    float: left;
    margin-bottom: 100px;
  }
}

@media only screen and (min-width: $mobileBreakpoint) {
}
