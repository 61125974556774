#services {
  padding-top: 4em;
}

.main {
  .section:first-of-type {
      margin-top: $headerHeightMobile;
      height: calc( auto - #{$headerHeightMobile} );
      @media only screen and (min-width:$mobileBreakpoint) {
          margin-top: 0;
          height: calc( 100vh - #{$headerHeight} );
          min-height: 100vh;
      }

    &.section-punch {
      height: auto;
    }
  }
  @media only screen and (min-width: 768px) {
    .main .section:first-of-type {
      margin-top: 0;
      height: 100vh;
    }
  }
}

.title-header {
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: 0.5em 2% 1.25em 2% !important;

  .title-section, .subtitle {
    color: white;
  }
  .title-section {
    font-size: 21px;
    text-transform: uppercase;
    font-weight: 800;
    margin: 0;
  }

  .subtitle {
    font-size: 13px;
    font-weight: 500;
    margin: 10px 0 50px 0;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    background-image: url("/assets/img/title_section_icon.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 165px auto;
  }

  @media only screen and (min-width:$mobileBreakpoint) {
    margin: 0.5em 2% 3em 2% !important;

    .title-section {
      font-size: 28px;
    }

    .subtitle {
      font-size: 20px;
    }
  }
}
