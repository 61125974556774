section.section {
  min-height: 85vh;
  //overflow: auto; // bug fix
  overflow: hidden; // bug fix
  //margin-top: -70px;
  //transition: all 3s 1s;

  h1, h2 {
    margin-top: -20px;
    margin-bottom: 55px;

    @media only screen and (min-width: 1140px) {
      margin-top: 0;
    }
  }

  &.section-invert { // sections avec text clair sur fond foncé
    color: white;
    text-shadow: none;
    //background-color: $darkColorBackground;
    background-image: linear-gradient(180deg, #191919 0%, #000000 100%);

    h1, h2 {
      color: $primaryColorReverse;
      text-shadow: none;
    }
    .button {
      color: white;
    }
  }
  &.section-color { // sections avec text clair sur fond foncé
    color: white;
    text-shadow: none;
    background-image: linear-gradient(180deg, #950604 0%, #BA2725 100%);

    h1, h2 {
      color: $primaryColorReverse;
      text-shadow: none;
    }
    .button {
      color: white;
    }
  }
  &.section-light { // sections avec text clair sur fond foncé
    color: $darkColorBackground;
    text-shadow: none;
    background-color: $LightBackground;

    h1, h2 {
      color: $darkColorBackground;
      text-shadow: none;
    }
    .button {
      color: white;
    }
  }
  &.section-white { // sections avec text clair sur fond foncé
    color: $darkColorBackground;
    text-shadow: none;
    background-color: $WhiteBackground;

    h1, h2 {
      color: $darkColorBackground;
      text-shadow: none;
    }
    .button {
      color: white;
    }
  }

  &.leftdown {
    clip-path: polygon(0 2%, 100% 0, 100% 100%, 0% 98%);
    @media only screen and (min-width: 768px) {
      clip-path: polygon(0 5%, 100% 0, 100% 100%, 0% 95%);
    }
  }
  &.leftupdown {
    clip-path: polygon(0 3%, 100% 0, 100% 100%, 0% 100%);
    @media only screen and (min-width: 768px) {
      clip-path: polygon(0 5%, 100% 0, 100% 100%, 0% 100%);
    }
  }
  &.rightdown {
    clip-path: polygon(0 0%, 100% 2%, 100% 98%, 0 100%);
    @media only screen and (min-width: 768px) {
      clip-path: polygon(0 0%, 100% 5%, 100% 95%, 0 100%);
    }
  }
  &.rightupdown {
    clip-path: polygon(0 0%, 100% 2%, 100% 100%, 0 100%);
    @media only screen and (min-width: 768px) {
      clip-path: polygon(0 0%, 100% 5%, 100% 100%, 0 100%);
    }
  }

  @media only screen and (min-width: 768px) {
    //margin-top: -70px;
  }
  @media only screen and (min-width: 1140px) {
    //margin-top: -110px;
  }
}

@mixin sectionFlex() {
  display: flex;
  align-items: left;
  justify-content: left;
  padding: 10em 0;
  // Hack IE 11
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: block;
    flex: inherit;
  }
}

@import 'sections/punchline';
@import 'sections/media';
@import 'sections/video';
@import 'sections/features';
@import 'sections/information';
@import 'sections/portfolio';
