.header {
  .menu {
    position: absolute;
    right: 0;
    display: none;
    top: 10px;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      overflow: hidden;

      li {
        float: left;
        margin: 0 20px;
        a {
          color: white;
          text-transform: uppercase;
          display: block;
          text-align: center;
          text-decoration: none;
          font-family: $titleFontFamily;
          font-size: 1.16em;
          letter-spacing: 0.02em;

          &:hover {
            color: darken($primaryColorReverse, 20%);
          }

          &.phoneCall {
            font-size: 2em;
            font-weight: 700;
            line-height: 0.9em;
            cursor: default;

            &:hover {
              color: #fff;
            }
          }
          img {
            margin: 10px 0;
          }
        }
        .button-primary {
          font-size: 1.16em;
          text-shadow: none;
          border-radius: 2em;
          text-align: center;
          margin-top: 5px;
          letter-spacing: 0.02em;
          color: white;
          background-color: $primaryColor;
          border: 1px solid $primaryColor;

          &:hover {
            background-color: darken($primaryColor, 10%);
            border: 1px solid darken($primaryColor, 10%);
          }
        }
      }
    }
  }
}

.header.fixed {
  .menu {
    ul {
      li {
        a {
          &:hover {
          }

          &.phoneCall {
            &:hover {
            }
          }
        }
        .button-primary {
          &:hover {
            color: white;
          }
        }
        .button-folder {
          color: $primaryColor;

          &:hover {
            color: white;
          }
        }
      }
    }
  }
}

.main_menu {
  position: relative;
  //width: 100%;
  display: none;
  margin: 3em 0 5em 0;
  padding: 0;
  transition: all 0.8s;
  transition-timing-function: cubic-bezier(3, 1, 2, 7);
  z-index: 4;

  ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    align-content: center;

    li {
      a {
        color: white;
        font-weight: 800;
        font-size: 17px;
        text-transform: uppercase;
        text-decoration: none;

        &.scroll.active {
          color: $primaryColor;
          position: relative;
          width: 100%;
          display: block;
          transition: all 0.3s;
          transition-timing-function: cubic-bezier(3, 1, 2, 7);

          &:after {
            content: "";
            position: absolute;
            top: -19px;
            left: 42%;
            width: 14px;
            height: 86%;
            background-image: url("/assets/img/icn_flame.svg");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            transition: all 0.3s;
            transition-timing-function: cubic-bezier(3, 1, 2, 7);
          }
        }
      }
    }
  }

  &.fixed {
    position: fixed;
    top: 0;
    margin: 13px;
    width: auto;
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 28px;
    z-index: 888;
    transition: all 0.8s;
    transition-timing-function: cubic-bezier(3, 1, 2, 7);

    ul {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      align-content: center;
      padding: 0 25px;

      li {
        margin: 0 12px;
        text-align: left;

        a {
          color: white;
          font-weight: 800;
          font-size: 14px;
          text-transform: uppercase;
          text-decoration: none;
        }
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    display: block !important;
  }
}
